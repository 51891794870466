<template>
  <div class="all_page">
    <div class="header_cont">
      <div>
        <img
          class="top_img"
          src="../../assets/images/my/img_jfphb.png"
          alt=""
        />
      </div>
      <div class="center_condition">
        <div
          @click="timeAtion(index)"
          :class="{ time_style: true, time_seleted: selecID == index }"
          v-for="(item, index) in timeList"
          :key="index"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="cemter">
      <div class="Home_order" v-if="HomeList.length > 0">
        <div
          v-for="(item, index) in HomeList"
          :key="item.id"
          :class="{
            list_style: true,
            bgThird: index == 2,
            bgSecond: index == 1,
            bgFirst: index == 0,
          }"
        >
          <div class="all_lf">
            <img
              v-if="index == 0"
              class="ranknum"
              src="https://img.fanxinghuishou.cn/20241113/563554053.png"
              alt=""
            />
            <img
              v-if="index == 1"
              class="ranknum"
              src="https://img.fanxinghuishou.cn/20241113/216766227.png"
              alt=""
            />
            <img
              v-if="index == 2"
              class="ranknum"
              src="https://img.fanxinghuishou.cn/20241113/375734812.png"
              alt=""
            />
            <div class="ran_gh" v-if="index > 2">{{ index + 1 }}</div>
            <div class="list_left">
              <span>{{ item.name }}</span>
              <p>{{ item.phone }}</p>
            </div>
          </div>
          <div class="left_right">
            <span>{{ item.t_points }}</span
            ><span class="lf_code">分</span>
          </div>
        </div>
      </div>
      <div v-else class="Home_order_No g-flex-ac">
        <div class="order_No m-at">
          <img src="../../assets/images/home/zu8.png" alt="" />
          <span class="text fs-24">暂无数据</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      HomeList: [],
      timeList: [
        {
          name: "今日",
          id: 1,
        },
        {
          name: "本月",
          id: 2,
        },
        {
          name: "本年",
          id: 3,
        },
      ],
      selecID: 0,
    };
  },
  computed: {},
  watch: {},
  created() {
    this.onLoad();
  },
  methods: {
    timeAtion(index) {
      this.selecID = index;
      this.onLoad();
    },
    onLoad() {
      this.$http
        .getusersRanking({ mold: this.timeList[this.selecID].id })
        .then((res) => {
          console.log(res, "onload");
          this.HomeList = res.data.rank;
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="less">
.all_lf {
  display: flex;
  align-items: center;
}
.ran_gh {
  margin-right: 30px;
  width: 43px;
  height: 49px;
  text-align: center;
  line-height: 49px;
  font-family: DIN;
  font-weight: bold;
  font-size: 34px;
  color: #333333;
}
.ranknum {
  width: 43px;
  height: 49px;
  margin-right: 30px;
}
.left_right {
  font-family: PingFang SC;
  font-weight: bold;
  font-size: 30px;
  color: #577bb5;
}
.lf_code {
  font-size: 20px;
}
.list_left {
  span {
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 28px;
    color: #333333;
  }
  p {
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 26px;
    color: #999999;
  }
}
.list_style {
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto 20px auto;
  width: 700px;
  height: 130px;
  background: #ffffff;
  border-radius: 20px;
}
.bgFirst {
  background: linear-gradient(
    266deg,
    rgba(250, 244, 231, 0.5) 0%,
    #f9efde 100%
  );
}
.bgSecond {
  background: linear-gradient(
    266deg,
    rgba(237, 243, 251, 0.6) 0%,
    #e6eefa 100%
  );
}
.bgThird {
  background: linear-gradient(
    266deg,
    rgba(251, 240, 241, 0.6) 0%,
    #fbebec 100%
  );
}
.cemter {
  margin-top: 300px;
}
.Home_order {
  padding-top: 40px;
  box-sizing: border-box;
  //   height: calc(100vh - 88px);
}
.Home_order_No {
  //   margin-top: 300px;
  width: 100%;
  min-height: 500px;
  .order_No {
    width: 134px;
    text-align: center;
    img {
      width: 134px;
      height: 174px;
    }
    .text {
      color: #666;
    }
  }
}
.time_style {
  margin-right: 21px;
  text-align: center;
  line-height: 60px;
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 28px;
  color: #333333;
  width: 140px;
  height: 60px;
  background: #f5f7f9;
  border-radius: 30px;
}
.time_seleted {
  margin-right: 21px;
  text-align: center;
  line-height: 55px;
  width: 140px;
  height: 60px;
  background: rgba(87, 123, 181, 0.12);
  border-radius: 30px;
  border: 1px solid #577bb5;
  font-family: PingFang SC;
  font-weight: 800;
  font-size: 30px;
  color: #577bb5;
}
.header_cont {
  position: fixed;
  top: 0;
}
.all_page {
  width: 100vw;
  min-height: 100vh;
  background: #f8f8f8;
}
.top_img {
  width: 100vw;
  height: 270px;
}
.center_condition {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 100;
  margin-top: -50px;
  width: 100vw;
  box-sizing: border-box;
  padding: 0 25px;
  height: 100px;
  background: linear-gradient(0deg, #f8f8f8 0%, #ffffff 100%);
  border-radius: 30px 30px 0px 0px;
}
</style>
